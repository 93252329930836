@use './variable';

body {
  position: fixed;
  width: 100%;
  height: 100%;
}

.toastSuccessStyle {
  background: #d31048 !important;
  color: #fff !important;
  box-shadow: 3px 3px 25px 3px rgba(0, 0, 0, 0.5) !important;
  font-size: 14px;
}

.toastSuccessProgressBar {
  background: #fff !important;
}

.toastErrorStyle {
  background: #fff !important;
  color: #d31048 !important;
  box-shadow: 3px 3px 25px 3px rgba(0, 0, 0, 0.5) !important;
  font-size: 14px;
}

.toastErrorProgressBar {
  background: #d31048 !important;
}

.Toastify__close-button {
  color: #e0e0e0 !important;
}

.permissionTableAlert {
  background-color: #D22248 !important;
  color: #fff !important;
}

.permissionTableScroll {
  overflow-x: auto;
}

@media only screen and (min-width:768px){
    /* overwritting defaults*/
  body {
    font-size: variable.$fontSize;
  }
  .input-group-text {
    font-size: variable.$fontSize;
  }
  .form-control {
    font-size: variable.$fontSize;
  }
   /* Shared */
  .content {
    flex-direction: row;
  }
  .content-left {
    position: unset;
    width: 20%;
  }
  .content-right {
    width: 80%;
    margin: 0;
    padding: 10px;
}
  .filter-stickybtn {
    width: auto;
  }
  .filter-dropdown {
    width: 95%;
  }
  .taxonomy-container {
    width: 100%
  }
  .taxonomy-pagination-container {
    width: 100%
  }
  .mobile-hide
{
  display:block !important;
}
.mobile-show
{
  display:none !important;
}
.datepicker-form-control {
  font-size: variable.$fontSize;
}
.form-label {
  font-size: 16px;
}
.blue-bold-link {
  color: variable.$colorBlue !important;
  font-weight: bold;
  cursor: pointer;
}
  .custom-file-input {
    z-index: 0;
  }

  .list-group-item::before {
    content: "●";
    margin-right: 0.75rem;
  }
}
