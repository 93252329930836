$colorRed: #D22248;
$colorBlue: #034078;
$colorBg: #F8F8F8;
$colorBlack: #5d656d;
$colorBorder: #eeeeee;
$colorInputBorder: #ced4da;
$colorBtnDark: #353f48;
$colorBtnDarkHover: #808080;
$colorBtnLight: #808080;
$colorBtnLightHover: #353f48;
$colorRichBlack : #00072D;
$colorSuccess : #008000;
$fontSize: 0.8rem;
$fontSizeTitle: 1.5rem;