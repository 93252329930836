/* overwritting defaults*/
@use './variable';
body {
  font-size: variable.$fontSize;
  color:  variable.$colorBlack;
}
label {
  font-weight: 600;
}
a{
  text-decoration: none !important;
  color: variable.$colorRichBlack;
}
ul {
  list-style: none;
  padding: 0;
}
li {
  list-style: none;
}
.btn-primary {
  color: #fff;
  background-color: variable.$colorBlue;
  border-color: variable.$colorBlue;
}
.modal-title {
  width: 90%;
  text-align: center;
}
.modal-footer{
  justify-content: space-between;
}
*:focus {
  outline: none !important;
}
.custom-file {
  border: 1px solid variable.$colorInputBorder;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding-left: 10px;
  height: auto;
  overflow-x: hidden;
}
.input-group-text {
  font-size: variable.$fontSize;
}
.form-control {
  font-size: variable.$fontSize;
  color: variable.$colorBlack;
}
.form-control:focus {
  box-shadow: none !important;
}
.ag-theme-balham {
  background-color: transparent;
}
.react-datepicker-wrapper {
  display: block !important;
}
.react-datepicker__input-container {
  display: block !important;
}
.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  border: 0 !important;
  box-shadow: unset;
}
/* Header.js */
.header {
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
  align-items: center;
  background-color: variable.$colorRichBlack;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  width: 100%;
  padding: 15px;
  height: 60px;
}
.header-left {
  width: 60%;
}
.header-right {
  width: 40%;
}
/* App.js */
.content-container{
  background-color: #fff;
}
.content-wrapper{
  padding: 60px 0px 0px;
}
/* Home.js */
.login-container {
height: 70vh;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}
.login-header {
font-size: 30px;
color: #fff;
background-color: variable.$colorBlue;
width: 100%;
}
/* SideNav.js */
.menu-item {
display: flex;
flex-direction: row;
justify-content:space-between;
align-items: center;
list-style-type: none;
border-bottom: 1px solid variable.$colorBorder;
height: 50px;
}
.item {
padding: 15px 15px;
width: 80%;
height: 100%;
color: variable.$colorBlack;
text-transform: capitalize;
cursor: pointer;

&:hover{
background-color: variable.$colorBlue;
color: #fff;
}
}
.item-add {
border-left: 1px solid variable.$colorBorder;
padding-top: 10px;
text-align: center;
font-size: 18px;
cursor: pointer;
width: 20%;
height: 100%;

&:hover{
  background-color: variable.$colorBlue;
  color: #fff;
}
}
.taxonomy-menu-item {
display: flex;
align-items: center;
list-style-type: none;
border-bottom: 1px solid variable.$colorBorder;

&:hover{
  background-color: variable.$colorBlue;
  color: #fff;
}
}
.taxonomy-item-add {
font-size: 16px;
cursor: pointer;
text-align: center;
width: 20%;
height: 100%;
}
.taxonomy-list {
display: flex;
flex-direction: column;
border-bottom: 1px solid variable.$colorBorder;
}
.taxonomy-list-item {
padding: 15px 15px;
height: 100%;
color: variable.$colorBlack;
text-transform: capitalize;
cursor: pointer;

&:hover{
background-color: variable.$colorBlue;
color: #fff;
}
}
/* Login popup */
.login {
color: #fff;
cursor: pointer;
float: right;
}

.login-form-container {
  position: relative;
}

.login-input {
  width: 100%;
  border: 0;
  border-bottom: 1px solid variable.$colorBlack;
  outline: none;
  padding: 10px;

  &[type="password"] {
		margin-top: 10px;

		& ~ .login-input-clearbtn {
			 top: 20px;
		}
  }
}

.login-input-clearbtn {
  position: absolute;
  opacity: 0.5;
  right: 0px;
  top: 10px;
}

.login-forgotpwd {
cursor: pointer;

&:hover{
  opacity: 0.5;
}
}
/* Research.js */
.research-export-wrapper {
display: flex;
flex-direction: row;
justify-content:space-between;
}
/* FundingForm.js */
.funding-addinvestor-btn {
display: flex;
flex-direction: row-reverse;
margin-bottom: 10px;
}
/* UserForm.js */
.email-holder {
display: flex;
flex-direction: row;
justify-content:space-between;
align-items: center;
border: 1px solid variable.$colorBorder;
border-radius: 5px;
padding: 5px 15px;
margin-bottom: 5px;
}
/* PermissionForm.js */
.permission-policy-list-wrapper {
padding: 10px;
display: flex;
flex-wrap: wrap;

li {
  margin: 5px 5px 0 0;
  padding: 0 5px;
  border: 1px solid #ccc;
  border-radius: 10px;
  height: fit-content;
}

i {
  color: variable.$colorBlue;
  cursor: pointer;
}
}
/* QuestionForm.js */
.application-question-new-btn {
display: flex;
justify-content: space-between;
align-items: center;

button {
  height: 38px;
  width: 85%;
  border-radius: 5px;
}
}
/* Shared */
.content {
display: flex;
flex-direction: column;
justify-content:flex-start;
}
.content-left {
position: fixed;
width: 50%;
height: calc(100vh - 60px);
background-color: lightgray;
box-shadow: 0 3px 20px #000;
z-index: 9999;
overflow-y: scroll;
}
.content-left-bar {
padding: 10px 10px 0px;
font-size: 1.7rem;
position: fixed;
z-index: 999;
width: 100%;
background-color: #ffffff;
}
.content-right {
width: 100%;
height: calc(100vh - 60px);
overflow-y: scroll;
padding: 50px 10px 10px 10px;
margin: auto;
}
.content-right-dark {
width: 80%;
height: calc(100vh - 60px);
overflow-y: scroll;
padding: 10px;
margin: auto;
opacity:0.5;
}
.input-error {
border: 1px solid red;
}
.overlay {
width: 100%;
height: 100%;
position: fixed;
z-index: 999;
background: rgba(0, 0, 0, 0.7);
transition: all 0.5s ease-in-out
}
.grid-header {
display: flex;
flex-direction: row;
justify-content:space-between;
align-items: center;
flex-wrap: nowrap;
overflow: auto;
white-space: nowrap;
border: 1px solid variable.$colorBorder;
}
.grid-header-cell {
height: 60px;
padding: 5px;
border-right: 1px solid variable.$colorBorder;
white-space: pre-wrap;
}
.input-border {
border: 1px solid variable.$colorInputBorder;
}
.filter-container {
display: flex;
flex-direction: column;
background-color: variable.$colorBg;
border-top: 1px solid variable.$colorBg;
padding: 10px 15px;
margin-bottom: 20px;
}
.filter-row {
display: flex;
flex-direction: row;
justify-content: space-between;
}
.filter-firstline {
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
background-color: #fff;
border-radius: 5px;
border: 1px solid variable.$colorInputBorder;
}
.filter-spacing {
padding: 0;
margin-right: 15px;
}
.filter-nextline {
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;
padding: 0px 0px 0px 0px;
}
.filter-input {
height: 30px;
width: 100%;
padding-left: 15px;
font-size: 12px;
border: 0px solid #fff;
border-radius: 5px;
background-color: #FFF;
}
.filter-datepicker-form-control {
display: block;
width: 100%;
height: 30px;
padding: 0.375rem 0.5rem;
font-size: variable.$fontSize;
font-weight: 400;
line-height: 1.5;
color: #495057;
background-color: #fff;
background-clip: padding-box;
border: 0px solid variable.$colorInputBorder;
border-radius: 0.25rem;
transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.filter-list-container {
max-height: 250px;
overflow-y: scroll;
}
.filter-list-wrapper {
display: flex;
flex-direction: row;
justify-content: space-between;
padding-top: 5px;

li{
  &:hover {
	 color: variable.$colorRed;
  }
}
}
.filter-dropdown-wrapper {
display: flex;
flex-direction: row;
justify-content:flex-start;
flex-wrap: wrap;
margin: 10px 0px;
border-bottom: 1px solid #eee;
}
.filter-keyword {
border-radius: 15px;
border: 1px solid #ddd;
padding: 4px;
margin-right: 10px;
margin-bottom: 5px;
font-size: smaller;
}
.filter-keyword-dropdown {
background-color: #fff;
width: 100%;
}
.filter-dropdown
{
background-color: #fff;
position: absolute;
z-index: 9;
box-shadow: 4px 5px 20px 0px rgba(0, 0, 0, 0.09);
margin-top: 5px;
padding-left: 10px;
max-height: auto;
border-radius: 5px,
}
.filter-stickybtn {
padding-right: 10px;
padding-bottom: 10px;
display: flex;
flex-direction: column-reverse;
height: 250px;
position: sticky;
top: 5px;
text-align: right;
}
.filter-canclebtn {
background-color: variable.$colorBtnDark;
color: #fff !important;
font-size: 12px;
padding: 3px 12px;
border-radius: 4px;
cursor: pointer;


&:hover {
  color: #fff;
  background-color: variable.$colorBtnDarkHover;
}
}
.filter-applybtn {
background-color: variable.$colorBlue;
color: #fff !important;
font-size: 12px;
padding: 3px 12px;
border-radius: 4px;
cursor: pointer;

&:hover {
  color: #fff;
  background-color: variable.$colorBtnDark;
}
}
.filter-clearallbtn {
color:#D22248;
font-size: 12px;
padding-left: 0;
width: 135px
}
.filter-searchbtn {
background-color: variable.$colorBlue;
color: #fff !important;
font-size: 12px;
width: 135px;
height: 28px;
border-radius: 4px;
padding-top: 5px;
text-align: center;
margin-right: 10px;
cursor: pointer;

&:hover {
  color: #fff;
  background-color: variable.$colorBtnDark;
}
}
.filter-clearbtn {
background-color: variable.$colorBtnDark;;
color: #fff !important;
font-size: 12px;
width: 135px;
height: 28px;
border-radius: 4px;
padding-top: 5px;
text-align: center;
cursor: pointer;

&:hover{
  opacity: 0.7;
}
}
.sort-container {
padding-bottom: 20px;
position: relative;
}
.sort-wrapper {
padding: 10px;
font-size: 12px;
width: 260px;
}
.sort-color {
color: variable.$colorBlue;
text-transform: capitalize;
}
.sort-item {
list-style: none;
margin-bottom: 5px;
border-top: 1px solid variable.$colorBorder;
padding: 8px 10px;

&:hover {
  color: variable.$colorBlue;
}
}
.sort-dropdown {
box-shadow: 4px 5px 20px 0px rgba(0, 0, 0, 0.09);
position: absolute;
z-index: 9;
//top: 350px;
border-radius: 5px;
background-color: #ffffff;
padding: 0px 5px;
width: 260px;
max-height: 500px;
overflow-y: scroll;
}
.sort-selected {
float: right;
color: variable.$colorBlue;
}
.form-container {
padding: 10px 10px 0px;
border: 1px solid variable.$colorBorder;
}
.form-wrapper {
padding: 15px 0;
}
.btn-edit {
cursor: pointer;
color: variable.$colorBlue !important;
font-weight: 800;
}
.btn-delete {
cursor: pointer;
color: variable.$colorRed;
font-weight: 800;
}
.bottom-btn-wrapper {
display: flex;
flex-direction:row;
justify-content: flex-start;
align-items: center;
padding: 20px 0px;
}
.bottom-btn {
font-size: 12px;
width: 150px;
height: 30px;
border-radius: 4px;
margin-right: 20px;
}
.input-calendar {
position: relative;
left: 310px;
top: 40px;
z-index: 2;

}
.taxonomy-container {
font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
font-weight: 400;
font-size: 12px;
border: 1px solid #BDC3C7;
height: auto;
width:700px;
max-height: 800px;
margin-top: 20px;
overflow: auto;
white-space: nowrap;
}
.taxonomy-header {
display: flex;
flex-direction:row;
justify-content: space-between;
align-items: center;
height: 32px;
background-color: #f5f7f7;
color: rgba(0, 0, 0, 0.54);
border-bottom: 1px solid #BDC3C7;
}
.taxonomy-header-col-group {
width: 200px;
border-right: 1px solid #BDC3C7;
text-align: center;
font-weight: 600;
}
.taxonomy-header-col-name {
width: 250px;
border-right: 1px solid #BDC3C7;
text-align: center;
font-weight: 600;
}
.taxonomy-header-col-date {
width: 200px;
border-right: 1px solid #BDC3C7;
text-align: center;
font-weight: 600;
}
.taxonomy-header-col-action {
width: 200px;
text-align: center;
font-weight: 600;
}
.taxonomy-col-group {
width: 200px;
padding-left: 5px;
}
.taxonomy-col-name {
width: 250px;
padding-left: 5px;
}
.taxonomy-col-date {
width: 200px;
padding-left: 5px;
}
.taxonomy-col-action {
width: 200px;
padding-left: 5px;
}
.taxonomy-row {
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
border-bottom: 1px solid #d9dcde;
height: 32px;
overflow: auto;
white-space: nowrap;

&:hover {
  background-color: #ecf0f1;
}
}
.taxonomy-arrow {
color: rgba(0, 0, 0, 0.54);
padding-right: 5px;
}
.taxonomy-pagination-container {
align-items: center;
color: rgba(0, 0, 0, 0.54);
display: flex;
height: 32px;
//width: 700px;
justify-content: flex-end;
overflow: auto;
white-space: nowrap;
}
.taxonomy-pagination-span {
margin-left: 15px;
font-size: 12px;
}
.taxonomy-pagination-i {
color: rgba(0, 0, 0, 0.54);
font-size: 11px;
cursor: pointer;
}
.mobile-hide
{
  display:none !important;
}
.mobile-show
{
  display:block !important;
}
.datepicker-form-control {
display: block;
width: 100%;
height: 35px;
padding: 0.375rem 0.5rem;
font-size: 0.8rem;
font-weight: 400;
line-height: 1.5;
color: #495057;
background-color: #fff;
background-clip: padding-box;
border: 1px solid variable.$colorInputBorder;
border-radius: 0.25rem;
transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-label {
width: 100%;
font-weight: 400;
margin: 0;
line-height: 19px;
padding-top: 13px;
font-size: 0.8rem;
margin-bottom: 0.5rem;
}
.required {
color: variable.$colorRed;
position: relative;
}
.error {
color: red;
font-size: 12px;
}
.success {
color: variable.$colorSuccess;
font-size: 12px;
}
.form-filename-holder {
position: absolute;
color: hsl(0,0%,50%);
}
.pad {
margin: auto;
}

input:disabled {
background-color: #f2f2f2;
}

.disabled {
background-color: #f2f2f2;
}

.custom-file-input {
z-index: 0;
}

.geo-focus-list {
max-height: 155px;
overflow-y: auto;
margin-top: 10px !important;
padding-top: 0 !important;
}

/*.form-fileupload {
width: 100% !important;
border-radius: 4px;
cursor: pointer;
min-height: 38px;
overflow: hidden;
position: relative;
text-align: left;
color: variable.$colorBlack;

.form-uploadbutton {
  background-color: #798289;
  border-left: 1px solid variable.$colorBorder;
  color: #FFFFFF;
  position: absolute;
  right: 0;
  font-size: 12px;
  height: 38px;
  line-height: 38px;
  overflow: hidden;
  text-align: center;
  width: 100px;

  &:hover {
	 background: variable.$colorBtnDark;
  }
}
input {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  -moz-opacity: 0;
  filter: alpha(opacity=0);
  opacity: 0;
  font-size: 150px;
  height: 38px;
  z-index:20;
}
.form-filename-holder {
  position: absolute;
  float: left;
  height: 38px;
  line-height: 38px;
  width: 100%;
  border: 1px solid variable.$colorInputBorder;
  overflow: hidden;
  padding: 0 5px;
  text-align: left;
  vertical-align: middle;
  position: absolute;
  border-radius: 4px;
}
}*/
